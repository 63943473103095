@import url("reboot.css");
@import url("fontawesome/all.min.css");
@import url("adjustment.css");
// @import url("animation.css");

@import url("https://fonts.googleapis.com/css?family=Heebo:800");
@import url("https://fonts.googleapis.com/css?family=Noto+Sans+JP:700");
@import url("https://fonts.googleapis.com/css?family=Noto+Serif+JP:500");

html {
	font-size: 62.5%;
	@media screen and (max-width: 720px) {
		font-size: 56.3%;
	}
	@media screen and (max-width: 560px) {
		font-size: 50%;
	}
}

body {
	font-family: YuGothic, 游ゴシック, "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", メイリオ, Meiryo, "ＭＳ Ｐゴシック", sans-serif;
    font-size: 1.6rem;
    line-height: 1.8;
    color: rgb(51, 51, 51);
}
body.lock {
	overflow: hidden;
}
@media screen and (max-width: 479px) {
	html, body {
		font-size: calc(16 / 750 * 100vw);
	}
	body {
		min-width: 100%;
	}
}

a:link,
a:visited {
  color: #d6011b;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

a:hover,
a:active {
  opacity: 0.6;
}

a:focus,
*:focus {
  outline: none;
}

ul {
	list-style: none;
	padding: 0;
}
button{
	background-color: transparent;
	border: none;
	cursor: pointer;
	outline: none;
	padding: 0;
	appearance: none;
}
.inner {
	max-width: 124rem;
	margin: 0 auto;
	padding: 0 2rem;
}
img {
	max-width: 100%;
}


@media screen and (min-width: 769px) {
	.sp {
	  display: none !important;
	}
  }
  @media screen and (max-width: 768px) {
	.pc {
	  display: none !important;
	}
  }
  @media screen and (min-width: 735px) {
	a[href^="tel:"] {
	  pointer-events: none;
	}
  }
  *[class^=row-],
  *[class*=" row-"] {
	opacity: 0;
  }

  *[class^=row-],
  *[class*=" row-"] {
	opacity: 0;
  }

  *[class^=row-] > *,
  *[class*=" row-"] > * {
	float: left;
  }

  *[class*=vh-r] > *,
  *[class*=vh-r] > *,
  *[class*=vw-r] > *,
  *[class*=em-r] > *,
  *[class*=rem-r] > *,
  *[class*=px-r] > *,
  *[class*="%-r"] > * {
	float: right;
  }

#Wrap {
	.page & {
		padding-top: 8rem;
	}
}

.h2 {
	margin: 3em 0 2em;
	font-size: 3rem;
	font-weight: 700;
	line-height: 1.4;
	text-align: center;
	font-family: "Noto Sans JP", sans-serif;
	letter-spacing: 0.25em;
  }

  .h2 span {
	display: block;
	font-size: 1.6rem;
	letter-spacing: 0;
	padding-top: 1rem;
	opacity: 0.6;
  }

  .h2-w {
	color: #fff;
  }

  .h3 {
	margin: 2.5em 0 1.5em;
	font-size: 3rem;
	font-weight: 700;
	line-height: 1.4;
	font-family: "æ¸¸æ˜Žæœ", YuMincho, "Noto Serif JP", "ãƒ’ãƒ©ã‚®ãƒŽæ˜Žæœ ProN W6", "Hiragino Mincho ProN", "HiraMinProN-W6", "HGæ˜ŽæœE", "ï¼­ï¼³ ï¼°æ˜Žæœ", "MS PMincho", "MS æ˜Žæœ", "Times New Roman", Meiryo, serif;
  }

  .h4 {
	margin: 1.6em 0 1.5em;
	font-size: 2rem;
	font-weight: 700;
	line-height: 1.4;
  }

  .h5 {
	margin: 1.4em 0 0.5em;
	font-size: 1.8rem;
	font-weight: 700;
	line-height: 1.4;
  }

  .h6 {
	margin: 1.2em 0 0.5em;
	font-size: 1.6rem;
	font-weight: 700;
	line-height: 1.4;
  }

  p {
	margin: 0.5em 0;
	text-align: justify;
	text-justify: inter-ideograph;
  }

  .lead-l {
	margin: 2em 0 1.3em;
	font-size: 4rem;
	font-weight: 700;
	line-height: 1.4;
	font-family: "æ¸¸æ˜Žæœ", YuMincho, "Noto Serif JP", "ãƒ’ãƒ©ã‚®ãƒŽæ˜Žæœ ProN W6", "Hiragino Mincho ProN", "HiraMinProN-W6", "HGæ˜ŽæœE", "ï¼­ï¼³ ï¼°æ˜Žæœ", "MS PMincho", "MS æ˜Žæœ", "Times New Roman", Meiryo, serif;
  }

  .lead {
	margin: 2em 0 1.3em;
	font-size: 2.4rem;
	font-weight: 700;
	line-height: 1.4;
	font-family: "æ¸¸æ˜Žæœ", YuMincho, "Noto Serif JP", "ãƒ’ãƒ©ã‚®ãƒŽæ˜Žæœ ProN W6", "Hiragino Mincho ProN", "HiraMinProN-W6", "HGæ˜ŽæœE", "ï¼­ï¼³ ï¼°æ˜Žæœ", "MS PMincho", "MS æ˜Žæœ", "Times New Roman", Meiryo, serif;
  }

  .lead-s {
	margin: 2em 0 1.3em;
	font-size: 2rem;
	font-weight: 700;
	line-height: 1.4;
	font-family: "æ¸¸æ˜Žæœ", YuMincho, "Noto Serif JP", "ãƒ’ãƒ©ã‚®ãƒŽæ˜Žæœ ProN W6", "Hiragino Mincho ProN", "HiraMinProN-W6", "HGæ˜ŽæœE", "ï¼­ï¼³ ï¼°æ˜Žæœ", "MS PMincho", "MS æ˜Žæœ", "Times New Roman", Meiryo, serif;
  }

  .cap {
	font-size: 1.4rem;
  }

  .cap-s {
	font-size: 1.2rem;
  }

  * .h2:nth-child(1),
  * .h3:nth-child(1),
  * .h4:nth-child(1),
  * .h5:nth-child(1),
  * .h6:nth-child(1),
  * .lead-l:nth-child(1),
  * .lead:nth-child(1),
  * .lead-s:nth-child(1),
  * p:nth-child(1) {
	margin-top: 0;
  }

  * .h2:last-child,
  * .h3:last-child,
  * .h4:last-child,
  * .h5:last-child,
  * .h6:last-child,
  * .lead-l:last-child,
  * .lead:last-child,
  * .lead-s:last-child,
  * p:last-child {
	margin-bottom: 0;
  }

  @media screen and (max-width: 768px) {
	.h2 {
	  margin: 3em 0 1em;
	}
  }
  .bt {
	position: relative;
	overflow: hidden;
	display: inline-block;
	text-align: center;
	padding: 1em 2em;
	min-width: 22rem;
	font-size: 1.6rem;
	font-weight: 700;
	color: #fff !important;
	text-decoration: none;
	line-height: 1.2;
	background-color: #333;
	white-space: pre-wrap;
	-webkit-transition: opacity 0.3s ease-out;
	transition: opacity 0.3s ease-out;
  }

  input.bt {
	border: none;
  }

  .bt span {
	position: relative;
	display: inline-block;
	z-index: 1;
  }

  .bt i {
	line-height: 1;
  }

  .bt-il i,
  .bt-ir i {
	position: absolute;
	z-index: 1;
	top: 50%;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
  }

  .bt-il i {
	left: 0.8em;
  }

  .bt-ir i {
	right: 0.8em;
	-webkit-transition: right 0.2s ease-out;
	transition: right 0.2s ease-out;
  }

  .bt-ir:hover i {
	right: 1.2em;
  }

  .bt:before {
	content: "";
	width: 1rem;
	height: 1rem;
	opacity: 0;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
	border-radius: 50%;
	z-index: 0;
	-webkit-transition: all 0.2s ease-in;
	transition: all 0.2s ease-in;
  }

  .bt:hover {
	opacity: 1;
  }

  .bt:hover:before {
	opacity: 1;
	-webkit-transform: scale(30, 30);
	transform: scale(30, 30);
  }

  .bt-l {
	font-size: 2rem;
	padding: 1.5em 2em;
  }

  .bt-s {
	font-size: 1.4rem;
	min-width: 18rem;
  }

  .bt-ic {
	padding: 0.7em 0.8em;
  }

  .bt-ir {
	padding-left: 3em;
	padding-right: 3em;
  }

  .bt-il {
	padding-left: 3em;
	padding-right: 3em;
  }

  .bt-border {
	color: #333 !important;
	background-color: transparent;
	border: solid 1px rgba(0, 0, 0, 0.3);
  }

  .bt-border:hover {
	color: #fff !important;
  }

  .bt-border:before {
	background-color: #333;
  }

  .bt-border-w {
	color: #fff !important;
	background-color: transparent;
	border: solid 1px rgba(255, 255, 255, 0.3);
  }

  .bt-border-w:hover {
	color: #333 !important;
  }

  .bt-border-w:before {
	background-color: #fff;
  }

  .bt-red {
	background-color: #d6011b;
	color: #fff !important;
  }

  .bt-red:hover {
	background-color: #ed7836;
  }

  .list {
	margin: 1em 0;
  }

  .list-ul > li,
  .list-ol > li {
	margin-left: 2rem;
	margin-bottom: 0.6rem;
	font-size: 1.6rem;
	line-height: 1.4;
  }

  .list-ul > li {
	list-style: disc;
	list-style-position: outside;
  }

  .list-ol {
	padding-left: 0;
	counter-reset: item;
	list-style-type: none;
  }

  .list-ol > li {
	padding-left: 1.3em;
	text-indent: -1.3em;
  }

  .list-ol > li:before {
	content: counter(item) ".";
	padding-right: 0.5em;
	counter-increment: item;
	font-weight: 700;
	color: #d6011b;
  }

  .list-notes > li {
	margin-bottom: 0.6rem;
	padding-left: 1em;
	font-size: 80%;
	text-indent: -1em;
	line-height: 1.4;
	opacity: 0.7;
  }

  .list-td {
	display: table;
	width: 100%;
  }

  .list-td > li {
	display: table-row;
  }

  .list-td > li .t,
  .list-td > li .d {
	display: table-cell;
	padding: 1rem 0;
  }

  .list-td-s > li .t,
  .list-td-s > li .d {
	padding: 1rem 0;
  }

  .list-td > li .t {
	padding-right: 2rem;
	font-size: 80%;
	color: #333;
  }

  .list-d > li .t,
  .list-d > li .d {
	padding: 2rem;
  }

  .list-d > li .t {
	font-weight: 700;
	color: #d6011b;
	font-size: 1.6rem;
	white-space: nowrap;
  }

  .list-td li:nth-child(odd) {
	background: rgba(255, 255, 255, 0.8);
  }

  @media screen and (max-width: 414px) {
	.list-td-resp > li .t,
  .list-td-resp > li .d {
	  display: block;
	}

	.list-td-resp > li .t {
	  padding-bottom: 0;
	  border: none;
	}

	.list-td-resp > li .d {
	  padding-top: 1rem;
	}
  }
  * .list:nth-child(1) {
	margin-top: 0;
  }

  * .list:last-child {
	margin-bottom: 0;
  }

  .table-wrap {
	width: 100%;
	overflow-x: auto;
  }

  .table {
	width: 100%;
	margin: 2em 0;
	border: solid 1px #ddd;
  }

  .table th,
  .table td {
	border-right: dotted 1px #ddd;
	border-bottom: dotted 1px #ddd;
  }

  .table th {
	padding: 1rem;
	background-color: #f6f6f6;
	vertical-align: middle;
  }

  .table td {
	padding: 2rem;
  }

  .table-s td {
	padding: 1rem;
  }

  @media screen and (max-width: 414px) {
	.table-resp tr,
  .table-resp th,
  .table-resp td {
	  display: block;
	  width: 100%;
	}
  }
  * .table:nth-child(1) {
	margin-top: 0;
  }

  * .table:last-child {
	margin-bottom: 0;
  }

  .box {
	margin: 2em 0;
	padding: 3rem;
  }

  .box-border {
	border: solid 1px #ddd;
  }

  .box-light_gray {
	background-color: #eee;
  }

  * .box:nth-child(1) {
	margin-top: 0;
  }

  * .box:last-child {
	margin-bottom: 0;
  }

  .lg-backdrop.in {
	opacity: 0.8;
  }

  .owl-carousel-pg .owl-dots {
	position: relative;
	z-index: 999;
	width: 100%;
	margin-top: -2rem;
	padding: 0 1rem;
	text-align: right;
  }

  .owl-carousel-pg .owl-dots .owl-dot {
	width: 1rem;
	height: 1rem;
	margin-left: 0.5rem;
  }

  .owl-carousel-pg .owl-dots .owl-dot span {
	display: inline-block;
	width: inherit;
	height: inherit;
	background-color: #999;
	opacity: 0.8;
  }

  .owl-carousel-pg .owl-dots .active span {
	background-color: #fff;
	opacity: 1;
  }


header {
	position: fixed;
	z-index: 999999;
	top: 0;
	left: 0;
	width: 100%;
	height: 15rem;
	-webkit-transition: all 0.3s ease-out;
	transition: all 0.3s ease-out;
	display: flex;
	align-items: center;
	justify-content: space-between;
	.page &, .home.scroll & {
		height: 8rem;
		background-color: #fff;
		#header-logo {
			padding: 1.5rem 0 1.5rem 2rem;
		}
		#nav {
			padding: 0 4rem 0 0;
			> ul {
				> li {
					> a {
						color: #333;
						&::before {
							border: solid 1px rgba(0, 0, 0, 0.2);
						}
					}
					&:last-child > a {
						color: #fff;
					}
					> ul {
						li {
							background-color: #fff;
							a {
								border: solid 1px rgba(0, 0, 0, 0.1);
								color: #333;
								:hover {
									color: #fff;
									background-color: #333;
								}
							}
						}
					}
				}
			}
		}
		#nav_bar {
			// margin: 1rem 0 0;
			&::after,
			span:before,
			span:after {
				background-color: #333;
			}
		}
	}
	#header-logo {
		position: relative;
		z-index: 2;
		height: inherit;
		padding: 4rem 0 4rem 4rem;
		-webkit-transition: padding 0.3s ease-out;
		transition: padding 0.3s ease-out;
		float: left;
		img {
			width: auto;
			height: 100%;
		}
	}
	.lang {
		z-index: 1;
		position: absolute;
		top: 0;
		right: 0;
		margin: 0;
		a {
			display: block;
			width: 3.5rem;
			height: 3.5rem;
			color: #fff;
			background-color: rgba(0, 0, 0, 0.6);
			text-align: center;
			text-decoration: none;
			line-height: 3.5rem;
			font-size: 1.8rem;
			font-weight: 700;
		}
		@media screen and (max-width: 768px) {
			z-index: 10000;
		}
	}
	#nav {
		padding: 0 4rem 0 0;
		-webkit-transition: padding 0.3s ease-out;
		transition: padding 0.3s ease-out;
		> ul {
			display: flex;
			> li {
				position: relative;
				margin-right: 3rem;
				&.active {
					border-bottom: solid 3px #d6011b;
					&:hover {
						> a::before {
							opacity: 0;
						}
					}
				}
				&:last-child {
					margin-right: 0;
					> a {
						padding: 1.5rem 4rem;
						background-color: #d6011b;
						&:hover {
							opacity: 1;
							background-color: #ed7836;
						}
						&::before {
							display: none;
						}
					}
				}
				> a {
					position: relative;
					z-index: 1;
					color: #fff;
					text-decoration: none;
					line-height: 1;
					font-size: 1.6rem;
					font-weight: 700;
					&::before {
						position: absolute;
						top: -1rem;
						left: -1rem;
						content: "";
						width: calc(100% + 2rem);
						height: calc(100% + 2rem);
						border: solid 1px rgba(255, 255, 255, 0.6);
						opacity: 0;
						-webkit-transition: all 0.2s ease-out;
						transition: all 0.2s ease-out;
						-webkit-transform: scale(1, 0.2);
						transform: scale(1, 0.2);
					}
					i {
						font-size: .7em;
						vertical-align: middle;
					}
				}
				&:hover {
					> a {
						opacity: 0.7;
						&::before {
							opacity: 1;
							-webkit-transform: scale(1, 1);
							transform: scale(1, 1);
						}
					}
					> ul {
						height: 10rem;
					}
				}
				> ul {
					position: absolute;
					z-index: 1;
					left: -1rem;
					height: 0;
					overflow: hidden;
					margin-top: 8px;
					-webkit-transition: all 0.2s ease-out;
					transition: all 0.2s ease-out;
					li {
						a {
							display: block;
							padding: 1rem 2rem;
							border: solid 1px rgba(255, 255, 255, 0.4);
							color: #fff;
							text-decoration: none;
							font-weight: 700;
							white-space: nowrap;
						}
						&:first-child a {
							border-bottom: none !important;
						}
						&:hover {
							opacity: 1;
							color: #333;
							background-color: #fff;
						}
					}
				}
			}
		}
	}

	#nav_bar {
		position: relative;
		z-index: 2;
		display: none;
		width: 8rem;
		height: 6rem;
		// margin: 4rem 0 0 auto;
		&:hover {
			cursor: pointer;
		}
		span {
			position: relative;
			display: block;
			width: inherit;
			height: inherit;
			&::before {
				top: calc(50% - .5px);
			}
			&::after {
				bottom: 28%;
			}
		}
		&::after,
		span::before,
		span::after {
			position: absolute;
			left: 25%;
			content: "";
			display: block;
			width: 50%;
			height: 1px;
			background-color: #fff;
			-webkit-transition: all 0.2s ease-out;
			transition: all 0.2s ease-out;
		}
		&::after {
			top: 28%;
		}
		&:hover {
			&::after {
				top: 35%;
			}
			span:after {
				bottom: 35%;
			}
		}
		.nav_open &::after,
		.nav_open & span:before,
		.nav_open & span:after {
		  background-color: #fff;
		}

		.nav_open &::after {
		  top: calc(50% - .5px);
		  -webkit-transform: rotate(135deg);
		  transform: rotate(135deg);
		}

		.nav_open & span:before {
		  opacity: 0;
		}

		.nav_open & span:after {
		  bottom: calc(50% - .5px);
		  -webkit-transform: rotate(-135deg);
		  transform: rotate(-135deg);
		}
	}

	@media screen and (max-width: 1200px) {
		#nav {
			> ul {
				> li {
					margin-right: 1.5rem;
					&:last-child {
						margin-right: 0;
						> a {
							padding: 1.5rem 2rem;
						}
					}
				}
			}
		}
	}

	@media screen and (max-width: 1024px) {
		#header-logo {
			height: inherit;
			padding: 4rem 0 4rem 2rem;
			.lang {
				display: none;
			}
		}
		#nav {
			position: absolute;
			top: 0;
			z-index: 1;
			width: 100%;
			height: 0;
			overflow: hidden;
			padding: 0 !important;
			background-color: rgba(0, 0, 0, 0.8);
			-webkit-transition: height 0.2s ease-out;
			transition: height 0.2s ease-out;
			> ul {
				padding: 16rem 2rem 2rem;
				margin-bottom: 14rem;
				display: block;
				> li,
				> li.active {
					margin: 0;
					float: none;
					border: solid 1px rgba(255, 255, 255, 0.2);
					border-bottom: none;
					overflow: hidden;
				}
				> li {
					> a {
						display: block;
						color: #fff !important;
						font-size: 2rem;
						padding: 2.5rem 2rem;
					}
					&:hover {
						> a:before {
							opacity: 0;
						}
						> ul {
							height: auto;
						}
					}
					> ul {
						position: inherit;
						left: 0;
						height: auto;
						overflow: inherit;
						margin: 0 2rem 2rem;
						padding-bottom: 1px;
						li {
							a {
								font-size: 2rem;
							}
						}
					}
					&:last-child {
						border: none;
						a {
							padding: 4rem 2rem;
						}
					}
				}
			}
			.scroll &,
			.page & {
				> ul {
					padding: 10rem 2rem 2rem;
					> li {
						> ul {
							li {
								background-color: transparent;
								a {
									border: solid 1px rgba(255, 255, 255, 0.4);
									color: #fff;
									&:hover {
										color: #fff;
										background-color: #333;
									}
								}
							}
						}
					}
				}
			}
			.nav_open & {
				height: 100vh;
				overflow: auto;
				-webkit-overflow-scrolling: touch;
			}
		}
		#nav_bar {
			display: block;
		}
		.nav_open {
			position: fixed;
			z-index: -1;
			width: 100%;
			height: 100%;
		}
	}
	@media screen and (max-width: 414px) {
		height: 8rem;
		background-color: #fff;
		#header-logo {
			padding: 1.5rem 0 1.5rem 2rem;
		}
		#nav {
			padding: 3rem 2rem 0 0;
			> ul {
				> li {
					> a {
						color: #333;
					}
					&:last-child > a {
						color: #fff;
					}
				}
			}
			.nav_open & {
				> ul {
					padding: 10rem 2rem 2rem;
				}
			}
		}
		// #nav_bar {
		// 	margin: 1rem 1rem 0 0;
		// }
	}
}

footer {
	@media screen and (max-width: 479px) {
	}
}

#mv {
	.page & {
		position: relative;
		z-index: 1;
		padding: 8rem 8rem;
		background: #bb102e;
		background: -webkit-gradient(linear, left top, right top, from(#bb102e), color-stop(50%, #d60131), to(#ed7836));
		background: linear-gradient(to right, #bb102e 0%, #d60131 50%, #ed7836 100%);
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#bb102e", endColorstr="#ed7836", GradientType=1);
		h1 {
			position: relative;
			z-index: 1;
			display: table;
			width: 100%;
			color: #fff;
			span {
				display: table-cell;
				white-space: nowrap;
				vertical-align: bottom;
				&.e {
					width: 1%;
					padding-right: 4rem;
					line-height: 0.8;
					font-family: "Heebo", sans-serif;
					font-size: 6rem;
				}
				&.l {
					border-bottom: solid 1px #fff;
				}
				&.j {
					width: 1%;
					padding-left: 4rem;
					text-align: right;
					font-size: 3rem;
					font-family: "Noto Sans JP", sans-serif;
				}
			}
		}
		&::before,
		&::after {
			position: absolute;
			content: "";
			opacity: 0;
			-webkit-transition: all 0.7s ease-out;
			transition: all 0.7s ease-out;
		}
		&::before {
			top: 0;
			left: 0;
			width: 20%;
			height: 100%;
			background: rgba(0, 0, 0, 0.06);
			-webkit-transform-origin: left top;
			transform-origin: left top;
			-webkit-transform: skew(-15deg);
			transform: skew(-15deg);
			width: 40%;
			opacity: 1;
			-webkit-transform: skew(-30deg);
			transform: skew(-30deg);
		}
		&::after {
			bottom: 0;
			left: 0;
			width: 25%;
			height: 100%;
			background: rgba(0, 0, 0, 0.1);
			-webkit-transform-origin: left bottom;
			transform-origin: left bottom;
			-webkit-transform: skew(30deg);
			transform: skew(30deg);
			width: 50%;
			opacity: 1;
			-webkit-transform: skew(60deg);
			transform: skew(60deg);
		}
	}
	@media screen and (max-width: 1024px) {
		.page & {
		  padding: 6rem 4rem;
		  h1 {
			  span {
				  &.e {
					font-size: 5rem;
				  }
				  &.j {
					font-size: 2.4rem;
				  }
			  }
		  }
		}
	}
	@media screen and (max-width: 768px) {
		.page #mv {
		  padding: 6rem 3rem;
		}

		.page #mv h1 .e {
		  font-size: 4rem;
		}

		.page #mv h1 .j {
		  font-size: 2rem;
		}
	  }
	  @media screen and (max-width: 736px) {
		.page & {
		  padding: 6rem 3rem;
		  h1 {
			display: block;
			span {
				display: block;
				&.e {
					padding: 0;
					width: 100%;
					margin-bottom: 1rem;
				}
				&.j {
					padding: 0;
					width: 100%;
				}
			}
		  }
		}
	}
	@media screen and (max-width: 414px) {
		.page & {
			h1 {
				span {
					text-align: center !important;
					&.e {
						font-size: 3.5rem;
					}
					&.j {
						font-size: 2rem;
					}
				}
			}
		}
	}
}
main {
	.crumbList {
		background-color: #eee;
		.inner {
			padding-top: 1rem;
			padding-bottom: 1rem;
			font-size: 1.4rem;
			color: #999;
			a {
				text-decoration: none;
			}
		}
	}
	.inner {
		padding-top: 10rem;
		padding-bottom: 10rem;
		.n {
			color: #333;
			font-weight: 700;
		}
	}
	@media screen and (max-width: 768px) {
		.inner {
			padding-top: 6rem;
			padding-bottom: 6rem;
		}
	}
	@media screen and (max-width: 414px) {
		.crumbList {
			display: none;
		}
	}
}

#pageup {
	position: fixed;
	z-index: 100;
	bottom: 0;
	right: 0;
	opacity: 0;
	a {
		position: relative;
		display: block;
		display: block;
		width: 6rem;
		height: 6rem;
		background-color: #333;
		background-color: rgba(0, 0, 0, 0.8);
		span {
			position: relative;
			display: block;
			width: inherit;
			height: inherit;
			overflow: hidden;
			padding-top: 100%;
			&::before,
			&::after {
				bottom: 30%;
				width: 30.5%;
			}
			&::before {
				right: 24%;
				-webkit-transform: rotate(45deg);
				transform: rotate(45deg);
			}
			&::after {
				left: 24%;
				-webkit-transform: rotate(-45deg);
				transform: rotate(-45deg);
			}
		}
		&::after {
			top: 30%;
			left: 25%;
			width: 50%;
		}
	}
	a::after,
	span::before,
	span::after {
	  position: absolute;
	  content: "";
	  display: block;
	  width: 50%;
	  height: 2px;
	  background-color: #fff;
	  -webkit-transition: all 0.2s ease-out;
	  transition: all 0.2s ease-out;
	}
	&:hover span::before,
	&:hover span::after {
	  bottom: 45%;
	}
}

footer {
	#footer-catch {
		background-image: url(../images/f_bg.png);
		background-repeat: no-repeat;
		background-position: center center;
		background-size: cover;
		.inner {
			padding-top: 18rem;
			padding-bottom: 18rem;
			h3 {
				color: #d6011b;
				line-height: 1.3;
				font-size: 4rem;
				font-family: "Heebo", sans-serif;
				letter-spacing: 0.1em;
			}
			h4 {
				color: #fff;
				font-size: 3rem;
				font-family: "Noto Sans JP", sans-serif;
				letter-spacing: 0.25em;
			}
			.btw a {
				width: 100%;
				padding: 5rem 2rem;
				color: #fff !important;
				background-color: #d6011b;
				font-size: 2rem;
				&:hover {
					background-color: #ed7836;
				}
			}
		}
	}
	#footer-link {
		.inner {
			padding-top: 6rem;
			padding-bottom: 6rem;
			ul {
				li {
					padding-top: 0.5rem;
					padding-bottom: 0.5rem;
					a {
						position: relative;
						display: inline-block;
						color: #333;
						text-decoration: none;
						font-weight: 700;
						&::before {
							position: absolute;
							bottom: 0;
							right: 0;
							content: "";
							width: 0;
							height: 1px;
							background-color: #d6011b;
							-webkit-transition: all 0.2s ease-out;
							transition: all 0.2s ease-out;
						}
						&:hover {
							&::before {
								left: 0;
								right: auto;
								width: 100%;
							}
						}
					}
					&.sub {
						margin-left: 1em;
						a {
							font-weight: 400;
						}
					}
					i {
						color: #d6011b;
					}
					&.git i,
					i.icon-lmp-other-win {
					  color: #333;
					}
					&.git2 i {
						color: #b6b6b6;
						font-size: 2rem;
					}
				}
			}
			#footer-logo {
				padding-right: 4rem;
				img {
					width: 100%;
					max-width: 18rem;
				}
			}
		}
	}
	#footer-copy {
		height: 6rem;
		overflow: hidden;
		background: #bb102e;
		background: -webkit-gradient(linear, left top, right top, from(#bb102e), color-stop(50%, #d60131), to(#ed7836));
		background: linear-gradient(to right, #bb102e 0%, #d60131 50%, #ed7836 100%);
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#bb102e", endColorstr="#ed7836", GradientType=1);
		p {
			padding: 1.5rem 7rem;
			color: #fff;
			text-align: center;
			line-height: 1.3;
			font-size: 1.2rem;
		}
	}
	@media screen and (max-width: 1024px) {
		#footer-catch {
			.inner {
				padding-top: 12rem;
				padding-bottom: 12rem;
			}
		}
	}
	@media screen and (max-width: 768px) {
		#footer-catch {
			.inner {
				padding-top: 8rem;
				padding-bottom: 8rem;
				h3 {
					text-align: center;
				}
				h4 {
					text-align: center;
				}
			}
		}
		#footer-logo {
			padding-right: 0;
			margin-bottom: 4rem;
		}
	}
}

.pagination {
	text-align: center;
	padding-top: 4rem;
  }

  .pagination .pagination_wrap a,
  .pagination .pagination_wrap span {
	display: inline-block;
	width: 4rem;
	height: 4rem;
	border: solid 1px #ddd;
	background-color: #fff;
	color: #ed7836;
	text-decoration: none;
	text-align: center;
	line-height: 1;
	line-height: 4rem;
	font-size: 2rem;
	font-weight: 700;
  }

  .pagination .pagination_wrap .pre {
	margin-right: 1rem;
  }

  .pagination .pagination_wrap .nex {
	margin-left: 1rem;
  }

  .pagination .pagination_wrap .current {
	border-color: #ed7836;
	color: #fff;
	background-color: #ed7836;
	margin: 0;
  }

  .pagination .pagination_wrap .inactive {
	margin: 0 0 0 -1px;
  }

  .pagination .pagination_wrap .still {
	border: none;
  }


#entry {
    position: relative;
    text-align: center;
    background: #bb102e;
    background: -webkit-gradient(linear, left top, right top, from(#bb102e), color-stop(50%, #d60131), to(#ed7836));
    background: linear-gradient(to right, #bb102e 0%, #d60131 50%, #ed7836 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#bb102e", endColorstr="#ed7836", GradientType=1);
    &::before,
    &::after {
        position: absolute;
        content: "";
        opacity: 0;
        -webkit-transition: all 0.7s ease-out;
        transition: all 0.7s ease-out;
    }
    &::before {
        top: 0;
        left: 0;
        height: 100%;
        background: rgba(0, 0, 0, 0.06);
        -webkit-transform-origin: left top;
        transform-origin: left top;
        width: 40%;
        opacity: 1;
        -webkit-transform: skew(-30deg);
        transform: skew(-30deg);
    }

    &::after {
        bottom: 0;
        left: 0;
        height: 100%;
        background: rgba(0, 0, 0, 0.1);
        -webkit-transform-origin: left bottom;
        transform-origin: left bottom;
        width: 50%;
        opacity: 1;
        -webkit-transform: skew(60deg);
        transform: skew(60deg);
    }
    .bt {
        position: relative;
        z-index: 1;
        background-color: #fff;
        color: #d60131 !important;
        width: 100%;
        max-width: 60rem;
        -webkit-transition: all 0.3s ease-out;
        transition: all 0.3s ease-out;
        &:hover {
            background-color: #ed7836;
            color: #fff !important;
        }
    }
}
.red {
    color: #d6011b;
}
.validate_error {
	color: red;
	font-size: 1em;
	font-weight: 400;
	display: block;
}

.googleform-wrapper {
	position: relative;
	width: 100%;
	height: 800px;
	// overflow-y: scroll;
	iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
  }
